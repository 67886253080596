import * as yup from "yup";
import {
  CostCenterOnlyDTO,
  schemaCostCenterOnlyDTO,
} from "../costCenter/CostCenterOnlyDTO";
import {
  ProjectOnlyDTO,
  schemaProjectOnlyDTO,
} from "../project/ProjectOnlyDTO";
import {
  schemaSubsidiaryDTO,
  SubsidiaryDTO,
} from "../subsidiary/SubsidiaryDTO";
import { CfopDTO, schemaCfopDTO } from "../cfop/CfopDTO";
import {
  ExemptionTaxDTO,
  schemaExemptionTaxDTO,
} from "./filters/ExemptionTaxDTO";

export interface CostCenterPepOnlyDTO {
  id: number;
  pepCode: string;
  pepDescription: string;
  isExempted: boolean;
  tax: number | null;
  taxWithExemption: number | null;
  currency: string;
  currencySymbol: string;
  project: ProjectOnlyDTO;
  subsidiary: SubsidiaryDTO;
  costCenter: CostCenterOnlyDTO;
  version: string;
  cfop: CfopDTO | null;
  exemptionTaxDtos: ExemptionTaxDTO[] | null;
}

export const schemaCostCenterPepOnlyDTO = yup.object({
  id: yup.number().integer().defined(),
  pepCode: yup.string().defined(),
  pepDescription: yup.string().defined(),
  isExempted: yup.boolean().defined(),
  tax: yup.number().nullable().defined(),
  taxWithExemption: yup.number().nullable().defined(),
  currency: yup.string().defined(),
  currencySymbol: yup.string().defined(),
  project: schemaProjectOnlyDTO.defined(),
  subsidiary: schemaSubsidiaryDTO.defined(),
  costCenter: schemaCostCenterOnlyDTO.defined(),
  version: yup.string().defined(),
  cfop: schemaCfopDTO.nullable(),
  exemptionTaxDtos: yup.array().of(schemaExemptionTaxDTO).nullable().defined(),
});
