import * as yup from "yup";

export interface ExemptionTaxDTO {
  effectiveDate: Date;
  value: number;
}

export const schemaExemptionTaxDTO = yup.object({
  effectiveDate: yup.date().defined(),
  value: yup.number().defined(),
});
