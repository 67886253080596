import { styled } from "@mui/material";

export const StyledCenteredPicker = styled("div")`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledNavigationBottomContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  padding-top: ${({ theme }) => theme.spacing(1)};
`;

export const StyledCalendarAndTimesheetSection = styled("div")`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - ${({ theme }) => theme.spacing(8)});
  padding-top: ${({ theme }) => theme.spacing(2.5)};
  gap: ${({ theme }) => theme.spacing(2.5)};
`;

export const StyledCalendarArea = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`;

export const StyledAhgoraLastUpdateArea = styled("div")`
  font-weight: bold;
  color: green;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  white-space: nowrap;
  position: absolute;
  bottom: 10px;
  z-index: 1;
`;

export const StyledWeekMonthTabContainer = styled("div")`
  width: 100%;
  height: 100%;
`;
