import { styled } from "@mui/material/styles";

interface StyledWeekTabContainerProps {
  hasAhgoraLastUpdateInfo: boolean;
}

export const StyledMonthTabContainer = styled(
  "div"
)<StyledWeekTabContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  padding-bottom: ${({ hasAhgoraLastUpdateInfo }) =>
    hasAhgoraLastUpdateInfo ? "25px" : "0"};
`;

export const StyledAttachInformation = styled("span")`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledTotalTable = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
`;

export const StyledTotalTable2 = styled("div")`
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 2;
`;

export const StyledTablesContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  overflow-y: auto;
  border-radius: 4px;
`;
