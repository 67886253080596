import * as yup from "yup";

export interface TimesheetPunchBalanceDTO {
  day: Date;
  minutes: number;
}

export const schemaTimesheetPunchBalanceDTO = yup.object({
  day: yup.date().defined(),
  minutes: yup.number().defined(),
});
