import { useContext, useRef } from "react";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { useProjectTableData } from "./hooks/useProjectTableData";
import { useCostCenterTableData } from "./hooks/useCostCenterTableData";
import {
  StyledMonthTabContainer,
  StyledTablesContainer,
  StyledTotalTable2,
} from "./index.styles";
import { useTotalTableData } from "./hooks/useTotalTableData";
import { useCreationForm } from "./hooks/useCreationForm";
import { useFillRecurrenceForm } from "./hooks/useFillRecurrenceForm";
import { TimesheetContext } from "../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";

export const MonthTab = () => {
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const { timesheetData } = useContext(TimesheetContext);

  const {
    creationButton: projectCreationButton,
    creationModal: projectCreateFirstModal,
  } = useCreationForm({
    creationType: "project",
    shouldShowButtonContained: true,
  });

  const {
    creationButton: costCenterCreationButton,
    creationModal: costCenterCreateFirstModal,
  } = useCreationForm({
    creationType: "costCenter",
    shouldShowButtonContained: true,
  });

  const {
    headCells: projectHeadCells,
    rows: projectRows,
    deletionModal: projectDeletionModal,
    editingModal: projectEditingModal,
    creationModal: projectCreationModal,
    attachActivityModal,
    timesheetObservationModal: projectTimesheetObservationModal,
  } = useProjectTableData();
  const {
    headCells: costCenterHeadCells,
    rows: costCenterRows,
    deletionModal: costCenterDeletion,
    editingModal: costCenterEditingModal,
    creationModal: costCenterCreationModal,
    attachPipedriveOrganizationModal,
    timesheetObservationModal: costCenterTimesheetObservationModal,
  } = useCostCenterTableData();

  const { RecurrenceButton, recurrenceModal } = useFillRecurrenceForm();

  const { rows } = useTotalTableData();
  return (
    <StyledMonthTabContainer
      hasAhgoraLastUpdateInfo={!!timesheetData?.lastAhgoraUpdate}
    >
      <StyledTablesContainer>
        <RecurrenceButton />
        {projectRows.length > 0 ? (
          <EnhancedTable
            ref={enhancedTableRef}
            headCells={projectHeadCells}
            uiSettings={{
              minTableHeight: "max-content",
              stickyHeader: true,
            }}
            paginationSettings={{
              usesPagination: false,
            }}
            rows={projectRows}
          />
        ) : (
          projectCreationButton
        )}
        {costCenterRows.length > 0 ? (
          <EnhancedTable
            ref={enhancedTableRef}
            headCells={costCenterHeadCells}
            uiSettings={{
              minTableHeight: "max-content",
              stickyHeader: true,
            }}
            paginationSettings={{
              usesPagination: false,
            }}
            rows={costCenterRows}
          />
        ) : (
          costCenterCreationButton
        )}
        <StyledTotalTable2>
          <EnhancedTable
            ref={enhancedTableRef}
            headCells={costCenterHeadCells}
            uiSettings={{
              minTableHeight: "max-content",
              stickyHeader: true,
            }}
            paginationSettings={{
              usesPagination: false,
            }}
            headerSettings={{
              hiddenHeader: true,
            }}
            rows={rows}
          />
        </StyledTotalTable2>
      </StyledTablesContainer>
      {projectDeletionModal}
      {projectEditingModal}
      {costCenterDeletion}
      {costCenterEditingModal}
      {projectCreationModal}
      {costCenterCreationModal}
      {projectCreateFirstModal}
      {costCenterCreateFirstModal}
      {attachActivityModal}
      {attachPipedriveOrganizationModal}
      {recurrenceModal}
      {projectTimesheetObservationModal}
      {costCenterTimesheetObservationModal}
    </StyledMonthTabContainer>
  );
};
