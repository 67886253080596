import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { useInvoiceRequestFormikConfig } from "../../shared/react/hooks/data/validation/useInvoiceRequestFormikConfig";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { CommonInvoiceRequestFormValues } from "../../shared/types/data/form/values.types";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import {
  UpdateReturnedToAnalysis,
  UpdateToReleased,
  getInvoiceById,
} from "../../../../../../../../../services/invoices/invoices.service";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { InvoiceRequestForm } from "../../shared/react/components/content/InvoiceRequestForm";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { DateOnly } from "../../../../../../../../../shared/common/classes/data/date/DateOnly";
import { InvoicesStatusEnum } from "../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { InvoiceTypeTranslationCodeMap } from "../../shared/react/mappers/InvoiceTypeTranslationCodeMap";
import { SimpleIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { uploadInvoiceFile } from "../../../../../../../../../services/InvoiceFiles/invoiceFiles.service";
import { InvoiceFilesTypeEnum } from "../../../../../../../../../shared/specific/enums/invoiceFiles/InvoiceFilesTypeEnum";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../../../services/applicationState/toast.service";
import { UpdateToReleasedDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/updateProcesses/updateToReleasedDTO";
import { UpdateToAnalysisDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/updateProcesses/updateToAnalysisDTO";
import { InvoiceTypeEnum } from "../../../../../../../../../shared/specific/enums/invoices/InvoiceTypeEnum";

interface OwnProps {
  reloadTablePage: () => void;
}

let uploadSuccess = false;

async function UpdateData(
  internalFormData: InvoiceDTO,
  formValues: CommonInvoiceRequestFormValues
) {
  const invoiceCompetenceData = formValues.invoiceCompetence.map((x) =>
    YearMonth.createFromDate(x)
  );
  const invoiceCopyToUserData = formValues.invoiceCopyToUser.map((x) => x.id);

  if (internalFormData.status === InvoicesStatusEnum.NotReleased) {
    const invoiceToUpdate: UpdateToReleasedDTO = {
      idCustomer: formValues.customer?.id ?? 0,
      idCostCenterPepToInvoice: formValues?.costCenterPep?.id ?? 0,
      idInvoiceGroup: internalFormData.idInvoiceGroup,
      invoiceIds: formValues.invoices.map((x) => x.id),
      invoiceType: formValues.invoiceType?.id ?? InvoiceTypeEnum.InvoiceNote,
      invoiceCompetence: invoiceCompetenceData,
      limitDate: formValues?.limitDate
        ? DateOnly.createFromDate(formValues.limitDate)
        : DateOnly.createFromNow(),
      invoiceFinancialResponsible: formValues.invoiceFinancialResponsible,
      serviceDescription: formValues.serviceDescription,
      launchInstructions: formValues.launchInstructions,
      invoiceNote: formValues.invoiceNote,
      invoiceCopyToUser: invoiceCopyToUserData,
      status: InvoicesStatusEnum.Released,
      requestDate: DateOnly.createFromNow(),
      invoiceFilesToDelete: formValues.invoiceFilesToDelete,
      requesterLogin: internalFormData.requesterLogin?.login ?? "",
      version: internalFormData.version,
    };

    await UpdateToReleased(invoiceToUpdate);
  } else {
    const invoiceToUpdate: UpdateToAnalysisDTO = {
      idCustomer: formValues.customer?.id ?? 0,
      idCostCenterPepToInvoice: formValues?.costCenterPep?.id ?? 0,
      invoiceIds: formValues.invoices.map((x) => x.id),
      invoiceType: formValues.invoiceType?.id ?? InvoiceTypeEnum.InvoiceNote,
      invoiceCompetence: invoiceCompetenceData,
      limitDate: formValues?.limitDate
        ? DateOnly.createFromDate(formValues.limitDate)
        : DateOnly.createFromNow(),
      invoiceFinancialResponsible: formValues.invoiceFinancialResponsible,
      serviceDescription: formValues.serviceDescription,
      launchInstructions: formValues.launchInstructions,
      invoiceNote: formValues.invoiceNote,
      invoiceCopyToUser: invoiceCopyToUserData,
      status: InvoicesStatusEnum.Analysis,
      requestDate: DateOnly.createFromNow(),
      invoiceFilesToDelete: formValues.invoiceFilesToDelete,
      responsibleLogin: internalFormData.responsibleLogin?.login ?? "",
      version: internalFormData.version,
    };

    await UpdateReturnedToAnalysis(invoiceToUpdate);
  }

  await uploadInvoiceFile(
    formValues.invoiceFilesToUpload,
    formValues.invoices.map((x) => x.id.toString()),
    InvoiceFilesTypeEnum.Request
  );
}

export const useEditInvoiceRequest = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const formikConfig = useInvoiceRequestFormikConfig();

  const {
    ContentButton: EditingInvoiceRequestButtonContainer,
    contentModal: editingInvoiceRequestModal,
  } = useFormikModalButton<
    CommonInvoiceRequestFormValues,
    InvoiceDTO,
    InvoiceDTO
  >({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: ({ internalFormData: invoice }) => {
        return (
          <Trans
            i18nKey="general.modal.data.edit.titleEditName"
            values={{ name: invoice?.milestone ?? "" }}
            components={{ italic: <em /> }}
          />
        );
      },
    },
    button: {
      FormButtonMemo: ({ onClick }) => {
        const { t } = useTranslation();

        return (
          <SimpleIconButton
            Icon={AppRegistrationIcon}
            color="warning"
            tooltipText={t("invoice.modal.data.edit.editInvoiceRequest")}
            onClick={onClick}
          />
        );
      },
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: invoice,
        setFormValues,
        setInternalFormData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();

        try {
          const invoiceData = await getInvoiceById(invoice.id);

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!invoiceData)
            return setModalError(
              t("invoice.errors.data.general.invoiceDoesNotExist")
            );

          setInternalFormData(invoiceData);
          setFormValues({
            customer: invoiceData?.customer,
            costCenterPep: invoiceData.costCenterPepToInvoice,
            invoiceType: invoiceData?.invoiceType
              ? {
                  id: invoiceData?.invoiceType,
                  label: t(
                    InvoiceTypeTranslationCodeMap[invoiceData?.invoiceType]
                  ),
                }
              : {
                  id: InvoiceTypeEnum.InvoiceNote,
                  label: t(
                    InvoiceTypeTranslationCodeMap[InvoiceTypeEnum.InvoiceNote]
                  ),
                },
            invoiceCompetence: invoiceData?.invoiceCompetence
              ? invoiceData.invoiceCompetence.map((x) => x.yearMonth.toDate())
              : [],
            selectedCompetence: null,
            limitDate: invoiceData?.limitDate
              ? invoiceData?.limitDate.toDate()
              : null,
            invoiceFinancialResponsible:
              invoiceData?.invoiceFinancialResponsible
                ? invoiceData?.invoiceFinancialResponsible.map((x) => x.email)
                : [],
            invoiceFinancialResponsibleSelected: "",
            serviceDescription: invoiceData?.serviceDescription ?? "",
            launchInstructions: invoiceData?.launchInstructions ?? "",
            invoiceNote: invoiceData?.invoiceNote ?? "",
            invoiceCopyToUser: invoiceData?.invoiceCopyToUser
              ? invoiceData.invoiceCopyToUser.map((x) => x.userCollaborator)
              : [],
            invoiceFilesToDelete: [],
            invoiceFilesToUpload: [],
            invoiceFilesList: [],
            invoices: invoiceData.invoiceGroup,
          });
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "invoice.errors.data.fetch.failedToFetchInvoice",
          });
          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) => (
        <InvoiceRequestForm
          {...rest}
          formMode="editing"
          invoice={internalFormData ?? undefined}
          reloadTablePage={reloadTablePage}
        />
      ),
      onSubmit: async ({
        formValues,
        internalFormData,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          if (!internalFormData)
            throw new Error("'invoice' cannot be falsy on submit.");

          if (
            formValues.invoiceFilesList.length < 1 &&
            formValues.invoiceFilesToUpload.length < 1
          ) {
            notifyError(t("invoice.errors.data.update.evidenceIsRequired"));
            return;
          }

          await UpdateData(internalFormData, formValues);
          uploadSuccess = true;

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          if (uploadSuccess) {
            reloadTablePage();
            closeModal();
            setSubmitting(false);
          }
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage: "invoice.errors.data.fetch.failedToUpdateInvoice",
          });
          closeModal();
          setSubmitting(false);
        }
      },
    },
  });

  return { EditingInvoiceRequestButtonContainer, editingInvoiceRequestModal };
};
