import { Typography } from "@mui/material";
import { TipPopoverButton } from "../../../../../../../../../shared/common/react/components/popovers/popoverButtons/TipPopoverButton";
import { InfoIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/InfoIconButton";

interface ExemptionTaxDto {
  effectiveDate: Date;
  value: number;
}

interface OwnProps {
  exemptionTaxDtos: ExemptionTaxDto[] | null;
}

export const TaxInfo = ({ exemptionTaxDtos }: OwnProps) => {
  return (
    <TipPopoverButton
      button={<InfoIconButton />}
      content={
        <div>
          {exemptionTaxDtos && exemptionTaxDtos.length > 0 ? (
            exemptionTaxDtos!.map((exemption, index) => (
              <div key={index}>
                <Typography variant="body2">
                  <strong>Ano:</strong>{" "}
                  {new Date(exemption.effectiveDate).getFullYear()}
                </Typography>
                <Typography variant="body2">
                  <strong>Valor de Desoneração:</strong> {exemption.value}%
                </Typography>
              </div>
            ))
          ) : (
            <Typography variant="body2">
              Nenhuma informação de desoneração disponível
            </Typography>
          )}
        </div>
      }
    />
  );
};
