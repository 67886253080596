import { Tooltip } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getCostCenterPepsOnly } from "../../../../../../../services/costCenterPeps/costCenterPeps.service";
import { DeleteIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { ExternalEnhancedTableExternalSinglePageLoader } from "../../../../../../../shared/common/react/components/table/EnhancedTable/shared/types/propsRef.types";
import { CostCenterPepOnlyDTO } from "../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { ProjectDTO } from "../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { ProjectStatus } from "../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "../useDeletionForm";
import { useEditingForm } from "../useEditingForm/useEditingForm";
import { useViewingForm } from "../useViewingForm";
import { EditIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import { usePermissionChecker } from "../../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";
import { TaxInfo } from "../../shared/react/components/TaxInfo";
import { StyledTaxCell } from "./index.styles";

interface OwnProps {
  project: ProjectDTO | null;
  reloadTable: () => void;
}

export const useTableData = ({ reloadTable, project }: OwnProps) => {
  const { t } = useTranslation();

  const { editingModal, EditingButton } = useEditingForm({
    reloadTable,
  });
  const { viewingModal, ViewingButton } = useViewingForm();
  const { deletionModal, DeleteButton } = useDeletionForm({
    reloadTable,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("subsidiaries.keywords.general.subsidiary"),
      t("costCenters.keywords.general.costCenter"),
      t("costCenterPeps.keywords.fields.pepCode"),
      t("general.keywords.fields.currency"),
      t("general.keywords.fields.tax"),
      t("general.keywords.fields.exemption"),
      t("costCenterPeps.keywords.fields.isExempted"),
      t("cfops.keywords.general.cfop"),
      {
        value: "",
        width: 152,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const { hasPermission: canEditPepAfterPlanning } = usePermissionChecker({
    restrictions: {
      type: PermissionType.CanEditPepAfterPlanning,
    },
  });

  const onCostCenterPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(async () => {
      if (!project)
        throw new Error(
          "'project' cannot be null when this function is called."
        );

      const checkCanEditPep = () => {
        if (canEditPepAfterPlanning) return true;
        return [ProjectStatus.Created, ProjectStatus.Planning].includes(
          project.status
        );
      };

      try {
        const costCenterPeps = await getCostCenterPepsOnly({
          filters: {
            idProject: project.id ?? undefined,
          },
        });

        const checkCanDeletePep = (costCenterPep: CostCenterPepOnlyDTO) => {
          const hasMoreThanTwoCostCenterPeps = costCenterPeps.length > 1;
          return (
            project.status === ProjectStatus.Created ||
            (project.status === ProjectStatus.Planning &&
              hasMoreThanTwoCostCenterPeps) ||
            costCenterPep.subsidiary.id !== project.subsidiary.id
          );
        };

        const rows = costCenterPeps.map(
          (costCenterPep): ExternalEnhancedRow => ({
            id: costCenterPep.id,
            cells: [
              costCenterPep.subsidiary.name,
              {
                value: costCenterPep.costCenter.code,
                CellRenderer: () => (
                  <Tooltip
                    title={costCenterPep.costCenter.name}
                    placement="top"
                  >
                    <div>{costCenterPep.costCenter.code}</div>
                  </Tooltip>
                ),
              },
              costCenterPep.pepCode,
              costCenterPep.currency,
              costCenterPep.tax ? `${costCenterPep.tax}%` : "-",
              {
                value:
                  costCenterPep.isExempted &&
                  costCenterPep.taxWithExemption &&
                  costCenterPep.taxWithExemption !== 0
                    ? `${costCenterPep.taxWithExemption}%`
                    : "-",
                CellRenderer: () => (
                  <>
                    {costCenterPep.isExempted &&
                    costCenterPep.taxWithExemption &&
                    costCenterPep.taxWithExemption !== 0 ? (
                      <StyledTaxCell>
                        <span>{costCenterPep.taxWithExemption}% </span>
                        <TaxInfo
                          exemptionTaxDtos={
                            costCenterPep.exemptionTaxDtos ?? []
                          }
                        />
                      </StyledTaxCell>
                    ) : (
                      "-"
                    )}
                  </>
                ),
              },
              costCenterPep.isExempted
                ? t("general.keywords.general.yes")
                : t("general.keywords.general.no"),
              costCenterPep.cfop
                ? `${costCenterPep.cfop.code} - ${costCenterPep.cfop.name}`
                : "-",
              {
                CellRenderer: () => (
                  <>
                    <ViewingButton {...costCenterPep} />
                    {checkCanEditPep() ? (
                      <Protected
                        restrictions={{
                          type: PermissionType.CostCenterPeps,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButton {...costCenterPep} />
                      </Protected>
                    ) : (
                      <EditIconButton disabled />
                    )}
                    {checkCanDeletePep(costCenterPep) ? (
                      <Protected
                        restrictions={{
                          type: PermissionType.CostCenterPeps,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButton {...costCenterPep} />
                      </Protected>
                    ) : (
                      <DeleteIconButton disabled />
                    )}
                  </>
                ),
                align: "right",
                paddingmode: "horizontal",
              },
            ],
          })
        );

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    }, [project?.id, project?.status, canEditPepAfterPlanning]);

  return {
    headCells,
    onCostCenterPageChange,
    editingModal,
    viewingModal,
    deletionModal,
  };
};
