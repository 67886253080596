import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { Paper } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  StyledAhgoraLastUpdateArea,
  StyledCalendarAndTimesheetSection,
  StyledCalendarArea,
  StyledNavigationBottomContainer,
  StyledWeekMonthTabContainer,
} from "./index.styles";
import { setPageTitle } from "../../../../../../services/applicationState/pageData.service";
import { useBottomNavigation } from "../shared/react/hooks/general/useBottomNavigation";
import { BottomNavigationEnum } from "../shared/types/data/form/general.types";
import { WeekTab } from "../tabs/WeekTab";
import { MonthTab } from "../tabs/MonthTab";
import { CalendarFilter } from "../shared/react/components/content/CalendarFilter";
import { TimesheetContext } from "../shared/react/contexts/TimesheetContext/TimesheetContextProvider";

export const TimesheetPageWithinContext = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setPageTitle(t("timesheets.keywords.general.timesheet"));
  }, [t]);

  const { bottomNavigationComponent, tabValue } = useBottomNavigation();

  const { timesheetData } = useContext(TimesheetContext);

  return (
    <>
      <StyledNavigationBottomContainer>
        {bottomNavigationComponent}
      </StyledNavigationBottomContainer>
      <StyledCalendarAndTimesheetSection>
        <StyledCalendarArea>
          <Paper>
            <CalendarFilter />
          </Paper>
          {timesheetData?.lastAhgoraUpdate && (
            <StyledAhgoraLastUpdateArea>
              <CheckCircleIcon color="success" fontSize="small" />
              <span>
                {t("timesheets.keywords.general.lastUpdateAhgora", {
                  date: timesheetData.lastAhgoraUpdate.toLocaleString(
                    i18n.language
                  ),
                })}
              </span>
            </StyledAhgoraLastUpdateArea>
          )}
        </StyledCalendarArea>
        <StyledWeekMonthTabContainer>
          {tabValue === BottomNavigationEnum.Week && <WeekTab />}
          {tabValue === BottomNavigationEnum.Month && <MonthTab />}
        </StyledWeekMonthTabContainer>
      </StyledCalendarAndTimesheetSection>
    </>
  );
};
