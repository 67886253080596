import { useCallback, useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FieldArrayRenderProps, FormikErrors, FormikProps } from "formik";
import { Typography } from "@mui/material";
import { startOfMonth } from "date-fns";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedRowCells,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { TimesheetContext } from "../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import {
  getDaysInMonth,
  getTimesheetLineIdentification,
  getWeekDays,
} from "../../../shared/helpers/data/timesheet/general.helpers";
import { TimesheetHeaderDay } from "../../../shared/react/components/content/TimesheetHeaderDay";
import { CalendarWeekCell } from "../../../shared/react/components/content/CalendarCell/WeekCell";
import {
  TimesheetWeekCreateProjectFormValues,
  TimesheetWeekFormValues,
} from "../shared/types/form.values";
import { AddIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/AddIconButton";
import { ProjectStatus } from "../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { DeleteIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { useDeletionForm } from "../../../shared/hooks/useDeletionForm";
import { AttachActivity } from "../shared/components/AttachActivity";
import {
  StyledExtraTimeObservationContainer,
  StyledExtraTimeText,
  StyledIdentificationAddCell,
  StyledIdentificationAddCellContainer,
  StyledIdentificationCell,
} from "../shared/common.styles";
import { ProjectCacheAutocompleteFormikWithoutPermissionType } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectCacheAutocompleteFormikWithoutPermissionType";
import { Observation } from "../shared/components/Observation";
import { ExtraTime } from "../shared/components/ExtraTime";

interface OwProps {
  formikProps: FormikProps<TimesheetWeekFormValues>;
  arrayprojectCreateFormikProps: FieldArrayRenderProps;
  reloadTablePage: () => void;
}

export const useTableDataProjects = ({
  formikProps,
  arrayprojectCreateFormikProps,
  reloadTablePage,
}: OwProps) => {
  const { t, i18n } = useTranslation();
  const { values, errors } = formikProps;
  const { remove, insert } = arrayprojectCreateFormikProps;

  const newItemRef = useRef<HTMLInputElement | null>(null);

  const { dateFilter, canEditTimesheet } = useContext(TimesheetContext);

  const weekDays = getWeekDays(dateFilter);

  const { DeletionButtonContainer, deletionModal } = useDeletionForm();

  const handleDeleteNewAppointment = (index: number) => {
    remove(index);
  };

  const headCells = useMemo(() => {
    const startOfCurrentMonth = startOfMonth(new Date());
    const startDateFilter = startOfMonth(dateFilter);
    const isPastMonth = startOfCurrentMonth > startDateFilter;
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        HeadRenderer: () => (
          <StyledIdentificationCell>
            {!isPastMonth && (
              <AddIconButton
                onClick={() => {
                  insert(values.projectCreateTimesheets.length, {
                    appointment: undefined,
                    observation: "",
                    days: new Array(getDaysInMonth(dateFilter)).fill("00:00"),
                    extraTime: new Array(getDaysInMonth(dateFilter)).fill(
                      "00:00"
                    ),
                  });
                  setTimeout(() => {
                    newItemRef.current?.focus();
                    newItemRef.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }, 0);
                }}
              />
            )}
            <span>{t("projects.keywords.general.project")}</span>
          </StyledIdentificationCell>
        ),
        value: t("projects.keywords.general.project"),
        canSort: false,
      },
    ];

    weekDays.forEach((day) => {
      if (day.date.getMonth() !== dateFilter.getMonth()) {
        headCells.push({
          value: "",
          width: 70,
          canSort: false,
        });
      } else {
        headCells.push({
          HeadRenderer: () => <TimesheetHeaderDay {...day} />,
          value: "",
          width: 70,
          canSort: false,
        });
      }
    });

    headCells.push({
      value: "",
      width: 70,
    });

    return headCells;
  }, [t, dateFilter, values.projectCreateTimesheets.length]);

  const onPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(async () => {
      const currentAppointments =
        values.projectTimesheets?.map(
          (timesheet, indexTimesheet): ExternalEnhancedRow => {
            const cells: ExternalEnhancedRowCells = [
              {
                CellRenderer: () => (
                  <StyledIdentificationAddCellContainer>
                    <StyledIdentificationAddCell>
                      <span>
                        {getTimesheetLineIdentification(
                          timesheet.timesheet,
                          t,
                          i18n
                        )}
                      </span>
                      <AttachActivity
                        idProject={timesheet.timesheet.project?.id ?? 0}
                        name={`projectTimesheets[${indexTimesheet}].activity`}
                        reloadTablePage={reloadTablePage}
                        textButton={
                          timesheet.activity?.description ??
                          t("timesheets.actions.general.associateActivity")
                        }
                        value={timesheet.activity}
                      />
                    </StyledIdentificationAddCell>
                    <StyledExtraTimeObservationContainer>
                      <Observation
                        name={`projectTimesheets[${indexTimesheet}].observation`}
                      />
                      <ExtraTime
                        weekDays={weekDays}
                        reloadTablePage={reloadTablePage}
                        field={`projectTimesheets[${indexTimesheet}].days[{{ day }}].extraTime`}
                      />
                    </StyledExtraTimeObservationContainer>
                  </StyledIdentificationAddCellContainer>
                ),
              },
            ];

            const weekDays = getWeekDays(dateFilter);
            weekDays.forEach((day) => {
              if (day.date.getMonth() === dateFilter.getMonth()) {
                cells.push({
                  CellRenderer: () => (
                    <div>
                      <CalendarWeekCell
                        name={`projectTimesheets[${indexTimesheet}].days[${day.date.getDate() - 1}].time`}
                      />
                      <div>
                        <StyledExtraTimeText
                          style={{
                            color: "red",
                          }}
                        >
                          {values.projectTimesheets[indexTimesheet]?.days[
                            day.date.getDate() - 1
                          ]?.extraTime ?? "00:00"}
                        </StyledExtraTimeText>
                      </div>
                    </div>
                  ),
                });
              } else {
                cells.push("");
              }
            });

            cells.push({
              CellRenderer: () => {
                const appointmentFromAllocation = timesheet.days.every(
                  (x) => x.time === "00:00" && x.extraTime === "00:00"
                );
                return (
                  <>
                    {canEditTimesheet && !appointmentFromAllocation && (
                      <DeletionButtonContainer {...timesheet.timesheet} />
                    )}
                  </>
                );
              },
            });

            return {
              id: indexTimesheet,
              cells,
            };
          }
        ) ?? [];

      const newAppointments =
        values.projectCreateTimesheets?.map(
          (timesheet, indexTimesheet): ExternalEnhancedRow => {
            const cells: ExternalEnhancedRowCells = [
              {
                CellRenderer: () => {
                  const error =
                    errors?.projectCreateTimesheets &&
                    errors.projectCreateTimesheets.length - 1 >= indexTimesheet
                      ? (errors.projectCreateTimesheets[
                          indexTimesheet
                        ] as FormikErrors<TimesheetWeekCreateProjectFormValues>)
                      : null;
                  return (
                    <StyledIdentificationAddCellContainer>
                      <StyledIdentificationAddCell ref={newItemRef}>
                        <ProjectCacheAutocompleteFormikWithoutPermissionType
                          name={`projectCreateTimesheets[${indexTimesheet}].appointment`}
                          filters={{
                            statusIncludeOnly: [
                              ProjectStatus.Ongoing,
                              ProjectStatus.Finalized,
                            ],
                            finalizedInTheCurrentMonth: true,
                          }}
                          textfieldProps={{
                            variant: "standard",
                            label: null,
                            placeholder: t("projects.keywords.general.project"),
                          }}
                          autocompleteProps={{
                            value: timesheet.appointment || null,
                          }}
                        />
                        <AttachActivity
                          idProject={timesheet.appointment?.id ?? 0}
                          name={`projectCreateTimesheets[${indexTimesheet}].activity`}
                          reloadTablePage={reloadTablePage}
                          textButton={
                            timesheet.activity?.description ??
                            t("timesheets.actions.general.associateActivity")
                          }
                          value={timesheet.activity}
                        />
                        {error && (
                          <Typography variant="caption" color="error">
                            {error.activity}
                          </Typography>
                        )}
                      </StyledIdentificationAddCell>
                      <StyledExtraTimeObservationContainer>
                        <Observation
                          name={`projectCreateTimesheets[${indexTimesheet}].observation`}
                        />
                        <ExtraTime
                          weekDays={weekDays}
                          reloadTablePage={reloadTablePage}
                          field={`projectCreateTimesheets[${indexTimesheet}].extraTime[{{ day }}]`}
                        />
                      </StyledExtraTimeObservationContainer>
                    </StyledIdentificationAddCellContainer>
                  );
                },
              },
            ];

            const weekDays = getWeekDays(dateFilter);
            weekDays.forEach((day) => {
              if (day.date.getMonth() === dateFilter.getMonth()) {
                cells.push({
                  CellRenderer: () => (
                    <div>
                      <CalendarWeekCell
                        name={`projectCreateTimesheets[${indexTimesheet}].days[${day.date.getDate() - 1}]`}
                      />
                      <div>
                        <StyledExtraTimeText
                          style={{
                            color: "red",
                          }}
                        >
                          {
                            values.projectCreateTimesheets[indexTimesheet]
                              .extraTime[day.date.getDate() - 1]
                          }
                        </StyledExtraTimeText>
                      </div>
                    </div>
                  ),
                });
              } else {
                cells.push("");
              }
            });

            cells.push({
              CellRenderer: () => (
                <>
                  {canEditTimesheet && (
                    <DeleteIconButton
                      onClick={() => handleDeleteNewAppointment(indexTimesheet)}
                    />
                  )}
                </>
              ),
            });

            return {
              id: indexTimesheet,
              cells,
            };
          }
        ) ?? [];

      return [...currentAppointments, ...newAppointments].map((x, i) => ({
        ...x,
        id: i,
      }));
    }, [dateFilter, values, errors, t, i18n]);

  return {
    headCells,
    // rows,
    onPageChange,
    deletionModal,
  };
};
