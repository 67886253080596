import * as yup from "yup";
import {
  schemaTimesheetMonthDTO,
  TimesheetMonthDTO,
} from "./TimesheetMonthDTO";
import {
  schemaTimesheetPunchBalanceDTO,
  TimesheetPunchBalanceDTO,
} from "./TimesheetPunchBalanceDTO";

export interface TimesheetsUserMonthDTO {
  timesheetMonths: TimesheetMonthDTO[];
  minutesSum: number;
  extraMinutesSum: number;
  hasTrustedRole: boolean;
  idSubsidiaryLastHistory: number | null;
  punchBalances: TimesheetPunchBalanceDTO[];
  lastAhgoraUpdate: Date | null;
}

export const schemaTimesheetsUserMonthDTO = yup.object({
  timesheetMonths: yup.array(schemaTimesheetMonthDTO).defined(),
  minutesSum: yup.number().integer().defined(),
  extraMinutesSum: yup.number().integer().defined(),
  hasTrustedRole: yup.boolean().defined(),
  idSubsidiaryLastHistory: yup.number().nullable().defined(),
  punchBalances: yup.array(schemaTimesheetPunchBalanceDTO).defined(),
  lastAhgoraUpdate: yup.date().nullable().defined(),
});
