import { useContext, useMemo } from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedRow,
  ExternalEnhancedRowCells,
} from "../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { TimesheetContext } from "../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { formatMinutesToHourMinute } from "../../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { StyledTotalTableCell } from "../shared/styles/common.styles";
import { TotalTableRow } from "../../WeekTab/index.styles";

export const useTotalTableData = () => {
  const { t } = useTranslation();
  const { dateFilter, timesheetData } = useContext(TimesheetContext);

  const rows: ExternalEnhancedRow[] = useMemo(() => {
    const extraminutes = timesheetData?.extraMinutesSum ?? 0;
    const minutes = timesheetData?.minutesSum ?? 0;
    const notAppointedMinutesSum =
      lodash.sum(timesheetData?.punchBalances.map((x) => x.minutes)) -
      (extraminutes + minutes);
    const notAppointedHours: ExternalEnhancedRow = {
      id: Math.random(),
      cells: [
        {
          value: t("timesheets.keywords.fields.notAppointedHours"),
          CellRenderer: () => (
            <StyledTotalTableCell>
              {t("timesheets.keywords.fields.notAppointedHours")}
            </StyledTotalTableCell>
          ),
        },
        "",
        {
          CellRenderer: () => (
            <StyledTotalTableCell
              sx={{
                color: notAppointedMinutesSum > 0 ? "red" : "inherit",
              }}
            >
              {notAppointedMinutesSum > 0
                ? formatMinutesToHourMinute(notAppointedMinutesSum)
                : "00:00"}
            </StyledTotalTableCell>
          ),
        },
        "",
      ],
      CustomTableRow: TotalTableRow,
    };

    const lines = [t("timesheets.keywords.fields.appointedHours")];
    const currentAppointments =
      lines.map((type, i): ExternalEnhancedRow => {
        const cells: ExternalEnhancedRowCells = [
          {
            CellRenderer: () => (
              <StyledTotalTableCell>{type}</StyledTotalTableCell>
            ),
          },
          "",
          {
            CellRenderer: () => (
              <StyledTotalTableCell>
                {formatMinutesToHourMinute(extraminutes + minutes)}
              </StyledTotalTableCell>
            ),
          },
        ];

        cells.push("");

        return {
          id: i,
          cells,
          CustomTableRow: TotalTableRow,
        };
      }) ?? [];

    return [
      ...currentAppointments.map((x, i) => ({
        ...x,
        id: i,
      })),
      notAppointedHours,
    ];
  }, [dateFilter, timesheetData, t]);

  return {
    rows,
  };
};
