import { Button } from "@mui/material";
import { useContext, useMemo } from "react";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { startOfMonth } from "date-fns";
import { ActivitiesWithNoPermissionAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/activities/ActivitiesWithNoPermissionAutocompleteFormik";
import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { TipPopoverButton } from "../../../../../../../../../../shared/common/react/components/popovers/popoverButtons/TipPopoverButton";
import { TimesheetContext } from "../../../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { ActivityDropdownDTO } from "../../../../../../../../../../shared/specific/DTOs/activity/ActivityDropdownDTO";

interface OwnProps {
  reloadTablePage: () => void;
  textButton: string;
  name: string;
  idProject: number;
  value: ActivityDropdownDTO | null;
}

export const AttachActivity = ({
  idProject,
  name,
  reloadTablePage,
  textButton,
  value,
}: OwnProps) => {
  const { dateFilter } = useContext(TimesheetContext);
  const yearMonth = useMemo(
    () => YearMonth.createFromDate(dateFilter),
    [dateFilter]
  );

  const isPastMonth = useMemo(() => {
    const startOfCurrentMonth = startOfMonth(new Date());
    const startDateFilter = startOfMonth(dateFilter);
    return startOfCurrentMonth > startDateFilter;
  }, [dateFilter]);

  return (
    <TipPopoverButton
      button={
        <Button
          startIcon={<InsertLinkIcon />}
          sx={{
            textTransform: "none",
          }}
          disabled={isPastMonth}
        >
          {textButton}
        </Button>
      }
      content={
        <ActivitiesWithNoPermissionAutocompleteFormik
          name={name}
          filters={{
            idProject,
            yearmonth: yearMonth,
            fromTimesheet: true,
          }}
          autocompleteProps={{
            sx: {
              width: "300px",
            },
            value: value || null,
          }}
        />
      }
    />
  );
};
